.footer-container {
    display: grid;
    gap: 10px;
    /* margin: 20px; */
    /* margin-top: 15%; */
    grid-template-columns: calc(40% - 0px) calc(30% - 0px) calc(30% - 0px); /* Adjust widths to account for gap */
    padding-top: 10%;
}

.footer-column {
    padding-left: 20%;
    text-align: left;
}

.footer-row {
    /* border: 1px solid #ccc; */
    /* margin-bottom: 10px; */
    padding: 10px;
    text-align: left;
}

img{
    padding-right: 15px;
}

.heading {
    color: #FFFFFF;
    font-size: 24px;
    font-weight: 600;
    width: 130.02px;
    height: 22.29px;
    top: 15.86px;
    left: 59.98px;
    gap: 0px;
    padding: 10px;
    /* margin-bottom: 20px; */
}

.footer {
    margin-top: 20px;
    text-align: center;
    border-top: 1px solid #FFFFFF;
    padding-top: 10px;
    color: #FFFFFF;
}

/* In your CSS file (e.g., App.css) */
.custom-link {
    color: white; /* Initial color */
    text-decoration: none; /* Remove underline */
}
  
  .custom-link:hover {
    cursor: pointer;
    color: #E4B32B; /* Color on hover */
  }
  
  @media (max-width: 600px) {
    .footer-container {
        display: grid;
        gap: 10px;
        grid-template-columns: calc(100% - 0px) calc(0% - 0px) calc(0% - 0px); /* Adjust widths to account for gap */
        padding-top: 30%;
    } 
    .footer-column {
        padding-left: 5%;
        text-align: left;
    }
    .heading {
        color: #FFFFFF;
        font-size: 18px;
        font-weight: 600;
        width: 130.02px;
        height: 0px;
        top: 15.86px;
        left: 59.98px;
        gap: 0px;
    }
    .footer-row {
        text-align: left;
    }
    
    .footer-row img{
        padding-right: 8px;
        height: 30px;
    }
    .footer {
        margin-top: 20px;
        text-align: center;
        border-top: 1px solid #FFFFFF;
        padding-top: 10px;
        color: #FFFFFF;
        font-size: 10px;
    }
}