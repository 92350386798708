.token-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  height: 80%;
  width: 100%;
}

.token-section {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 12px;
  /* padding: 16px; */
}

.token-card {
  position: relative;
  display: flex;
  height: 88px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  border-radius: 4px;
  /* padding: 8px; */
  border: 1px solid #E9E9E9;
}


.token-card-content {
  display: flex;
  max-width: 100px;
  flex-direction: column;
  align-items: start;
  gap: 4px;
}

.token-button {
  display: flex;
  cursor: pointer;
  align-items: center;
  text-align: center;
  font-size: 14px;
  outline: none;
  height: 32px;
  padding: 16px 0px 0px 16px;
  max-width: 100%;
  flex: 1;
  justify-content: space-between;
  gap: 6px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: transparent;
  border: none;
}

.token-button img {
  display: inline-flex;
  height: 24px;
  justify-content: center;
  align-items: flex-start;
  gap: 7px;
  flex-shrink: 0;
}

.token-balance {
  display: flex;
  gap: 5px;
  white-space: nowrap;
  font-size: 12px;
  color: #9CA3AF; 
  padding-left: 16px;
  padding-bottom: 28px;
  width: 300px;
}

.token-input-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: end;
  justify-content: center;
  gap: 12px;
  padding: 16px;
}

.token-input-wrapper {
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  transition: border-color 0.2s ease, color 0.2s ease;
  width: 100%;
  padding: 0;
  font-size: 18px;
  font-weight: 500;
  line-height: 1;
}

.token-input {
  width: 100%;
  background-color: transparent;
  color: black; 
  border: none;
  outline: none;
  text-align: right;
  font-size: 18px;
}

.token-estimation {
  display: flex;
  align-items: start;
  font-size: 12px;
  color: #9CA3AF; 
}



.connect-wallet-button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.token-arrow-button {
  position: absolute;
  inset: 0;
  margin: auto;
  display: flex;
  height: 36px;
  width: 36px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #374151; 
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  outline: none;
}

.token-arrow-button:hover {
  background-color: #4B5563; 
}

.token-arrow-button:active {
  background-color: #374151; 
}

.token-arrow-icon {
  fill: #D1D5DB; 
  padding-top: 4px;
  opacity: 0.8;
}

.token-arrow-icon:hover {
  opacity: 1;
}


.connect-wallet {
cursor: pointer;
  background-color: #fbbd08;
  color: #000;
  border: none;
  border-radius: 3px;
  padding: 10px 20px;
  font-size: 14px;
  font-family: 'Arial', sans-serif;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  display: flex;
}

.connect-wallet-button {
align-items: center;
justify-content: center;
font-size: 14px;
outline: none;
height: 32px;
padding: 8px 12px;
width: 100%;
background-color: #fbbd08; 
border: none;
color: #FFFFFF;
border-radius: 3px;
opacity: 0.8;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number]{
-moz-appearance: textfield;
}