.buy-tokens-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    /* text-align: left; */
    margin-top: 100px;
    width: 450px;
}

.buy-title {
    h1 {
        font-size: 40px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        padding-bottom: 20px;

    }

    span {
        color: #E4B325;
    }

}

.component-heading {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 0px;
}

.component-heading-text {
    color: black;
    font-size: 1.5rem;
    margin-left: 20px;
}


.swap-logo {
    fill: #333;
}

h1 {
    margin: 0;  /* Removes default margin */
    padding: 0; /* Removes default padding */
}

.buy-ves {
    width: 100%;
    height: 380px;
    margin: 0 auto;
    border: 1px solid #eaeaea;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background-color: white;
    position: relative;
    color: black;
    display: flexbox;
}

.payment-section {
    display: flex;
    align-items: center;
    justify-content: center;
}

.input-group,
.output-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.input-container {
    display: flex;
    align-items: center;
    width: 100%;
    /* border: 1px solid #e0e0e0; */
    border-radius: 8px;
    background-color: white;
}

.currency-icon {
    width: 24px;
    height: 24px;
    margin-right: 7px;
}

.currency-text {
    font-size: 16px;
    color: #333;
    margin-right: 5px;
}

.currency-symbol {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.output-group {
    margin-left: 20px;
}

.output-label {
    width: 50px;
}

.you-get {
    display: flex;
    border: none;
    border-right: 1px solid #eaeaea;
    padding: 5px 10px;
    background-color: #ffff;
    height: 20px;
    width: 190px;
}

.label {
    margin-bottom: 5px;
    font-size: 0.7rem;
    color: #333;
}

.you-pay {
    display: flex;
    border: none;
    border-right: 1px solid #e0e0e0;
    padding: 5px 10px;
    background-color: #ffff;
    height: 20px;
    width: 190px;
}

.buy-button {
    background-color: #E4B325;
    color: #ffffff;
    border: none;
    border-radius: 8px;
    padding: 5px 35px;
    font-size: 16px;
    font-family: 'Arial', sans-serif;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-left: auto;
    display: flex;
    flex-direction: column;
}

.connect-wallet-home {
    margin-top: 30px;
    width: 83%;
    cursor: pointer;
    background-color: #fbbd08;
    color: #000;
    border: none;
    border-radius: 3px;
    padding: 10px 20px;
    font-size: 16px;
    font-family: 'Arial', sans-serif;
    font-weight: bold;
    cursor: pointer;
    /* transition: background-color 0.3s;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); */
    /* display: flex; */
}

.buy-wallet-home:hover {
    background-color: #ab830b;
}

.buy-button:hover {
    background-color: #eab308;
}

.agreement {
    margin-top: 20px;
}

.estimated-cost {
    margin-bottom: 5px;
    font-size: 0.7rem;
    color: #333;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 7px;
}

.total-supply {
    margin-bottom: 5px;
    font-size: 0.7rem;
    color: #333;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 7px;
}

.agreement {
    margin-bottom: 5px;
    font-size: 0.7rem;
    color: #333;
    display: flex;
    align-items: center;
    margin-top: 7px;
}

.bottom-section {
    display: flex;
    align-items: center;
    margin-top: 20px;
}

@media (max-width: 600px) {
    .buy-tokens-container {
        width: 100%; /* Adjust width for mobile */
    }

    .buy-title {
        h1 {
            font-size: 28px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
    
        span {
            color: #E4B325;
        }
    
    }
    .buy-ves {
        width: 90%; /* Full width on mobile */
        height: auto; /* Adjust height if necessary */
        padding: 10px; /* Add some padding for better spacing */
    }

    .you-get,
    .you-pay {
        width: auto; /* Allow the width to fit content */
        height: auto; /* Allow height to fit content */
        padding: 10px; /* Increase padding for touch targets */
    }

    .buy-button,
    .connect-wallet-home {
        width: 100%; /* Full width buttons */
        margin-left: 0; /* Reset margin */
    }

    .estimated-cost,
    .total-supply,
    .agreement {
        font-size: 0.9rem; /* Slightly larger text for readability */
    }

    .component-heading-text {
        color: black;
        font-size: 16px;
        margin-left: 0px;
    }
}