.breadcrumb-icon {
    font-size: 20px;
    color: #007bff;
    cursor: pointer;
  }
  
  /* Fullscreen popup */
  .breadcrumb-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8); /* Dark background with opacity */
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: flex-start;
    z-index: 1; /* Ensure it's on top of everything */
    overflow: hidden;
  }
  
  .breadcrumb-popup-header {
    position: relative;
    top: 0px;
    right: 20px;
    height: 24px;
    padding: 20px 0px 20px;
    text-align: right;
  }
  
  .close-icon {
    font-size: 24px;
    color: white;
    cursor: pointer;
  }
  
  .breadcrumb-popup-content {
    display: flex; /* Align items in a row */
    gap: 10px; /* Space between each item */
    flex-direction: column;
    padding: 20px;
    background: transparent;
    border-radius: 8px;
    max-width: 90%;
    max-height: 90%;
    overflow-x: auto; 
  }

  .breadcrumb-item {
    display: flex;
    flex-direction: column; /* Keeps items in a column layout */
    align-items: center; /* Horizontally centers the text */
    justify-content: center; /* Vertically centers the text */
    font-size: 24px;
    color: #FFF;
    text-decoration: none;
    padding: 20px 0; /* Adjust padding to suit your layout needs */
    border-top: 0.5px solid grey; /* Border only at the top */
    
  }

  .breadcrumb-item:hover {
    display: flex;
    flex-direction: column; /* Keeps items in a column layout */
    align-items: center; /* Horizontally centers the text */
    justify-content: center; /* Vertically centers the text */
    font-size: 24px;
    color: #FFF;
    text-decoration: none;
    padding: 20px 0; /* Adjust padding to suit your layout needs */
    border-top: 0.5px solid grey; /* Border only at the top */ 
  }


  .connect-wallet-header {
    background-color: #fbbd08;
    color: #000;
    border: none;
    border-radius: 3px;
    padding: 10px 20px;
    font-size: 14px;
    font-family: 'Arial', sans-serif;
    font-weight: bold;
    cursor: pointer;
}