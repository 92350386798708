body {
    font-family: Arial, sans-serif;
}

.about-helper-column {
    width: 90%;
    margin: 0 auto;
}



.text-content {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 5%;
    max-width: 50%;
    /* width: 200px; */

}

.about-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
    padding: 10px;
    box-sizing: border-box;
}

.about-row img {
    max-width: 485px;
    flex: 1;
    /* Set the maximum width */
    width: 30%;
    /* Ensure images do not exceed 30% of container width */
    height: auto;
    /* Maintain aspect ratio */
    flex-shrink: 0;
}

.about-row h1 {
    color: #E4B32B;
    /* Heading color */
    /* font-family: Outfit; */
    font-size: 38px;
    font-weight: 700;
    line-height: 47.88px;
    text-align: left;


}

.about-row p {
    /* font-family: Outfit; */
    font-size: 26px;
    font-weight: 400;
    line-height: 32.76px;
    text-align: justify;
    width: 100%;
    /* Ensure paragraphs take full width of their container */
}




.about-title {
    /* font-family: Outfit; */
    font-size: 50px;
    font-weight: 700;
    line-height: 63px;
    text-align: center;
    color: #E4B32B;

}

.about-title-no-color {
    /* font-family: Outfit; */
    font-size: 50px;
    font-weight: 700;
    line-height: 63px;
    text-align: center;
}

/* Mobile view adjustments */
@media screen and (max-width: 768px) {
    .about-row {
        flex-direction: column;
        align-items: flex-start;
    }

    .text-content,
    .about-row img {
        max-width: 100%;
        width: 100%;
    }

    .about-row img {
        margin-bottom: 20px;
    }

    .about-row h1 {
        color: #E4B32B;
        /* Heading color */
        /* font-family: Outfit; */
        font-size: 18px;
        font-weight: 700;
        line-height: 47.88px;
        text-align: left;
    }

    .about-title-no-color {
        /* font-family: Outfit; */
        font-size: 27px;
        font-weight: 700;
        line-height: 63px;
        text-align: center;
    }
    .about-title {
        /* font-family: Outfit; */
        font-size: 27px;
        font-weight: 700;
        line-height: 63px;
        text-align: center;
        color: #E4B32B;
    
    }
}