html, body {
  height: 100%;
  margin: 0;
}

.App {
  text-align: center;
  color: #e0e0e0;
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.header-text {
  margin-top: 100px;
  color: #ffff;
}

.buy-tokens-header {
  color: #ffff;
  padding: 10px;
}

.content {
  display: flex;
  justify-content: space-around;
  padding: 20px;
  flex: 1;
}

.buy-VES {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.transaction-filter button {
  margin: 5px;
  background-color: #424242;
  color: #e0e0e0;
  border: none;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.transaction-filter button:hover {
  background-color: #616161;
}

.transaction-table table {
  width: 100%;
  border-collapse: collapse;
  color: #e0e0e0;
}

.transaction-table th,
.transaction-table td {
  border: 1px solid #555;
  padding: 8px;
  background-color: greenyellow;
}

body {
  position: relative;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-image: url('../assets/background_image_new.jpg'); 
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.9;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: black;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .content {
      flex-direction: column;
  }

  .transaction-table table {
      width: 100%;
  }

  .transaction-filter button {
      width: 100%;
  }
}
