.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    width: 100%;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: #1E2329;
}

.left-components {
    display: flex;
    align-items: center; /* Aligns items in the center vertically */
}

.img-logo {
    width: 40px; /* Adjust the width to your preference */
    height: 40px; /* Adjust the height to your preference */
    flex-shrink: 0;
    padding-right: 10px;
    cursor: pointer;
}

.logo-link {
    text-decoration: none; /* Remove underline */
    color: inherit; /* Inherit text color from parent */
    display: flex; /* Use flexbox to align items */
    align-items: center; /* Center items vertically */
    cursor: pointer; /* Indicate that it's clickable */
}
.logo-text {
    font-size: 20px;
    font-weight: bold;
    color: #fff; /* Change to your desired text color */
    flex-shrink: 0;
    margin: 0; /* Remove any default margin */
    cursor: pointer;
}

.connect-wallet-header {
    background-color: #fbbd08;
    color: #000;
    border: none;
    border-radius: 3px;
    padding: 10px 20px;
    font-size: 14px;
    font-family: 'Arial', sans-serif;
    font-weight: bold;
    cursor: pointer;
    /* transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease; */
     /* Transition effect */
}

.connect-wallet-header:hover {
    background-color: #fbbd08; /* Brighter color on hover */
    /* box-shadow: 0 4px 4px rgba(100, 100, 100, 100);  */
    transform: scale(1.05); /* Slightly increase size */

}

.wallet-section {
    display: flex;
    align-items: center;
    gap: 10px; /* Add spacing between wallet button and balance */
}

.wallet-section svg {
    cursor: pointer;
    height: 32px; /* Set height for .span */
    width: 32px;
    fill: #FFF;
}

.wallet-section svg:hover {
    transform: scale(1.05);
    fill: #E4B32B;

}
.balance {
    color: #fff; /* Adjust the color to match your design */
    font-size: 16px;
    font-weight: 700;
}

.custom-link-header {
    color: white; /* Initial color */
    text-decoration: none; /* Remove underline */
    padding-left: 50px;
    font-size: 14px;
}
  
  .custom-link-header:hover {
    cursor: pointer;
    color: #E4B32B; /* Color on hover */
    padding-left: 50px;
    font-size: 14px;
}
 

.info-box {
    position: absolute;
    background-color: white; /* White background for the info box */
    padding: 10px;
    border: 1px solid #ccc; /* Optional border */
    border-radius: 4px; /* Rounded corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Shadow for depth */
    top: 50px; /* Distance from the top */
    right: 20px; /* Distance from the right side */
    width: 300px;
    height: 300px;
    font-size: 12px;
    color: black;
    z-index: 10; /* Ensure it appears above other elements */
    white-space: nowrap; /* Prevent text wrapping */
    background: url('../assets/mobile_connect.png');
}


@media (max-width: 600px) {

    .custom-link-header {
        color: white; /* Initial color */
        text-decoration: none; /* Remove underline */
        padding-left: 15px;
        font-size: 12px;
    }   
    .custom-link-header:hover {
        cursor: pointer;
        color: #E4B32B; /* Color on hover */
        padding-left: 15px;
        font-size: 12px;
    }

    .connect-wallet-header {
        background-color: #fbbd08;
        color: #000;
        border: none;
        border-radius: 3px;
        padding: 8px 16px;
        font-size: 14px;
        font-family: 'Arial', sans-serif;
        font-weight: bold;
        cursor: pointer;
    }
    .logo-text{
        font-size: 14px;
    }
    .img-logo {
        width: 20px; /* Adjust the width to your preference */
        height: 20px; /* Adjust the height to your preference */
        flex-shrink: 0;
        padding-right: 10px;
        cursor: pointer;
    }
}

.disconnect{
    background-color: red;
}

.disconnect:hover{
    background-color: red;
}